/* TODO Add site wide styles */
@font-face {
  font-family: "Futura";
  src: url("./assets/fonts/FuturaDisplayBQRegular.otf") format("opentype");
}

#root {
  font-family: Futura;
}

.separator {
  margin-top: 5px;
  margin-bottom: 5px;
}

.errors {
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}

a:hover,
button:enabled:hover {
  cursor: pointer;
}

button:enabled:hover {
  cursor: pointer;
}

/* .app {
  position: relative;
} */
