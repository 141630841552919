.card-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.card-header p {
  margin-left: 10px;
}
.card-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  text-align: center;
}

.card-body {
  font-family: "Kaushan Script", cursive; /* Yu-Gi-Oh!-inspired font */
  /* background: linear-gradient(to top,rgba(255, 255, 255, 0.467) 0%, rgba(73, 150, 73, 0.706) 50%, rgba(128, 0, 128, 0.569) 60%, rgba(255, 166, 0, 0.567) 90%); */
  padding: 20px;
  border-radius: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  animation: glow 2s ease-in-out infinite;
}

.card-body.N {
  border-color: green;
  animation-name: glow-green;
  background-color: rgba(0, 128, 0, 0.4);
}

.card-body.R {
  border-color: purple;
  animation-name: glow-purple;
  background-color: rgba(128, 0, 128, 0.4);
}

.card-body.SR {
  border-color: orange;
  animation-name: glow-orange;
  background-color: rgba(255, 166, 0, 0.4);
}

@keyframes glow-green {
  0% {
    box-shadow: 0 0 5px #333, 0 0 10px #333, 0 0 15px green, 0 0 20px green;
  }
  50% {
    box-shadow: 0 0 10px #333, 0 0 20px green, 0 0 30px green, 0 0 40px green;
  }
  100% {
    box-shadow: 0 0 5px #333, 0 0 10px #333, 0 0 15px green, 0 0 20px green;
  }
}

@keyframes glow-purple {
  0% {
    box-shadow: 0 0 5px #333, 0 0 10px #333, 0 0 15px purple, 0 0 20px purple;
  }
  50% {
    box-shadow: 0 0 10px #333, 0 0 20px purple, 0 0 30px purple, 0 0 40px purple;
  }
  100% {
    box-shadow: 0 0 5px #333, 0 0 10px #333, 0 0 15px purple, 0 0 20px purple;
  }
}

@keyframes glow-orange {
  0% {
    box-shadow: 0 0 5px #333, 0 0 10px #333, 0 0 15px orange, 0 0 20px orange;
  }
  50% {
    box-shadow: 0 0 10px #333, 0 0 20px orange, 0 0 30px orange, 0 0 40px orange;
  }
  100% {
    box-shadow: 0 0 5px #333, 0 0 10px #333, 0 0 15px orange, 0 0 20px orange;
  }
}
