.displayInstructions:hover {
  transform: scale(1.2);
  transition: transform 0.2s;
  text-shadow:0 0 10px rgba(137, 43, 226, 0.234);
  box-shadow: 0 0 50px rgb(143, 194, 239);
  border-bottom-right-radius: 15px;
  z-index: 1;
}

.instructions-container {
  border-radius: 10px;
  background: linear-gradient(to right, goldenrod, rgb(185, 146, 146), goldenrod);
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
  font-size: 20px;
  overflow: hidden;
}

.generalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.tabDescription {
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 100%;
  width: 100%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  /* font-weight: bold; */
  text-align: left;

}

.modal-tabs button.tab {
  color: goldenrod;
  background-color: firebrick;
  border: solid black;
  border-radius: 8px;
  font-size: 20px;
  font-family: Futura;
  display: fixed;  
  }

.modal-tabs button.tab:hover {
  transform: scale(1.3);
  transition: transform 0.3s;
  color: white;
  z-index: 2;

}

.li-space {
  margin-bottom: 10px;
  list-style-type: disc;
}

.tab-contents {
  height: 100%;
  width: 100%;
  padding: 0 2%;
}

.tab {
  border: solid white;
  background-color: rgba(0, 0, 0, 0.421);
  border-radius: 10px;
  color: white; 
  /* margin: 1%; */
  height: 100%;
  /* width: 100%; */
  position: relative;
  flex-direction: column;
  align-items: center; 
  overflow: auto;

  /* display: absolute; */
}

.instructionTab {
  transform: scale(1.2);
  /* color: white; */
  transition: transform 0.5s;
  z-index: 1;
}

.tab-contents > * {
  padding: 2% 5%;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.tabDescription::-webkit-scrollbar{
  width: 8px; /* Width of the scrollbar */
}

.tabDescription::-webkit-scrollbar-thumb {
  background-color: #8f8f8f; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Radius of the scrollbar thumb */
}

.tabDescription::-webkit-scrollbar-track {
  background-color: #e1e1e1; /* Color of the scrollbar track */
  border-radius: 4px;
  /* border-top-right-radius: 19205px;
  border-top-left-radius: 205px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 19205px; */
}