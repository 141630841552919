.session-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #ff4f00, goldenrod);

  height: calc(100vh - 100px);
}

.signup-page {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 42% 55%;
  column-gap: 3%;
  box-sizing: border-box;
}

.camera-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  box-sizing: border-box;
  padding-left: 30%;
}

.camera-explain {
  margin-top: 15px;
 display: flex;
 flex-direction: column;
}
.camera-explain>span {
  margin-bottom: 2px;
}


.session-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.retake,
.input-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-field input {
  padding: 3px 8px;
}

.session-btn,
.capture-button {
  /* border: none; */
  background: none;
  display: relative;
  color: white;
  background-color: goldenrod;
  margin-top: 30px;
  border-radius: 8px;
  font-weight: bold;
  background-image: linear-gradient(to right, #ff7f50, #ff4f00);
  font-size: 18px;
  padding: 3px 8px;
}

.session-btn:enabled:hover,
.capture-button {
  color: red;
  box-shadow: 0 2px 4px green;
  /* background: linear-gradient(to right, #ff7f50, #ff4f00); */
}

.session-btn:disabled {
  color: lightgray;
  background: gray;
}

.session-header {
  font-size: 36px;
  margin-bottom: 10px;
}

.input-SU {
  display: block;
  border-radius: 8px;
  margin-top: 10px;
  height: 25px;
}

.session-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.session-form-container.signup {
  padding-right: 30%;
  box-sizing: border-box;
}

.processing-signup {
  padding: 2px 5px;
  background-image: linear-gradient(
    to right,
    #f9d76e,
    #ffbd38,
    #ffa200,
    #ffbd38,
    #f9d76e
  );
  background-size: 200% 100%;
  animation: shift 2s linear infinite;
}


