.enemy-container {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  height: 100%;
  padding-top: 40px;
}

/* .game-content.enemy-board.animate-out {
  transform: translateX(200%);
  transition: transform 1s ease;
}


@keyframes animateOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
} */


.enemy-images {
  display: flex;
  align-items: center;
}

.enemy-container.defeated {
  transform: translateX(200%);
  transition: transform 2s;
}


.enemy-image {
  background-color: transparent;
  max-width: 140px;
  max-height: 140px;
  object-fit: cover;
  animation: enemyHover 1.5s infinite alternate;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#enemy-image-1 {
  animation-delay: 0s;
}

#enemy-image-2 {
  animation-delay: 0.4s;
}

#enemy-image-3 {
  animation-delay: 0.2s;
}

@keyframes enemyHover {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.enemy-health {
  top: 30px;
  position: relative;
  display: flex;
  justify-content: center;
}

.enemy-health-bar-container-enemy-info {
  width: 90%;
  height: 20px;
  position: relative;
  margin-top: 30px;
}

.enemy {
  position: relative;
  max-height: 140px;
  max-width: 140px;
}

#boss {
  max-height: 250px;
  max-width: 250px;
  position: relative;
  top: -75px;  
  left: -80px;
}

.explosion {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}