.profile-container {
  display: grid;
  grid-template-columns: 40% 59%;
  column-gap: 1%;
  /* height: calc(100vh - 100px); */
  width: 100%;
  position: relative;
  height: calc(100vh - 100px);
  background:linear-gradient(to bottom, rgb(2, 2, 22),teal, cyan, rgb(57, 114, 188));

}


.profile-div {
  border: solid slategray;
  flex: 1;
  box-sizing: border-box;
  font-size: 20px;
  background-image: url("https://e0.pxfuel.com/wallpapers/69/1009/desktop-wallpaper-blue-minimalistic-electronic-technology-border-background-technology-technology-design-graphic-graphic-design-background-templates-thumbnail.jpg");
  background-size: cover;
  color: white;
  /* font-size: 40px; */
  text-shadow: 0 0 20px cyan;
}

.profile-text{
  font-size: 40px;
}

.defaultPic {
  width: 100%;
  height: 100%;
  background-color: rgba(105, 90, 205, 0.596);
  box-shadow: 0 0 20px violet;
}

.profile-pic {
  box-sizing: border-box;
  border: solid slategray;
  width: 100px;
  height: 100px;
  margin-left: 30px;
  margin-top: 10px;
  position: relative;
}

.profile-details {
  position: relative;
  font-size: 20px;
  padding: 30px;
  padding-bottom: 10px;
  box-sizing: border-box;
  color: white;
  text-shadow: 0 0 20px greenyellow;
}

.card-collection {
  font-size: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  align-content: flex-start;
  background-image: url("https://i.pinimg.com/736x/f2/3d/71/f23d71105fbe64ab8a5689494842f3c3--wallpaper-borders-art-pictures.jpg");
  background-size: cover;
}

.collection-title {
  color: white;
  font-size: 40px;
  text-shadow: 0 0 20px orange;
}

.card-index {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}

.profile-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.profile-container li {
  flex: 1 0 200px;
  max-width: 200px;
}

.gach {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 5%;
  padding-top: 20px;
  color: white;
  text-shadow: 0 0 20px blue;
}

.profile-deck {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 49%);
  gap: 2%;
}

.gachButton-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin: 10px 0;
}

.gachButton {
  border-radius: 20px;
  font-size: 20px;
  font-family: Futura;
  color: rgb(184, 248, 87);
  background: linear-gradient(
    to left,
    green,
    purple,
    orange,
    red,
    orange,
    purple,
    green
  );
  animation: gachAnimation 10s linear infinite;
  background-size: 200% 100%;
  /* position: absolute; */
  /* left: 30%; */
}

.gachButton:enabled:hover {
  transition: transform 0.1s;
  transform: scale(1.1);
}
.deck-deselect-button{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deselectButton {
  border-radius: 15px;
  font-size: 20px;
  font-family: Futura;
  background-color: firebrick;
  color: black;
  /* width: 240px; */
}

.deselectButton:hover {
  transition: transform 0.1s;
  transform: scale(1.1);
  color: ivory;
  text-shadow: 0 0 20px red;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
}

.selectButton,.sellButton {
  margin: 10px;
  background-color: green;
  border-radius: 15px;
  font-size: 20px;
  width: 170px;
  font-family: Futura;
}

.selectButton.selected {
  background-color: red;
}

.sellButton {
  background-color: gold;
}

.sellButton:hover {
  transition: transform 0.1s;
  transform: scale(1.1);
  color: ivory;
  text-shadow: 0 0 20px yellow;
}

.selectButton:hover {
  transition: transform 0.1s;
  transform: scale(1.1);
  color: ivory;
  text-shadow: 0 0 20px green;
}

@keyframes gachAnimation {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.profile-div,
.card-collection {
  position: sticky;
  top: 0;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #8f8f8f #e1e1e1;
}

.profile-div::-webkit-scrollbar,
.card-collection::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.profile-div::-webkit-scrollbar-thumb,
.card-collection::-webkit-scrollbar-thumb {
  background-color: #8f8f8f; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Radius of the scrollbar thumb */
}

.profile-div::-webkit-scrollbar-track,
.card-collection::-webkit-scrollbar-track {
  background-color: #e1e1e1; /* Color of the scrollbar track */
  border-radius: 4px;
}
.card-animation-container {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 15%;
  height: 400px;
  width: 400px;
  transform: translate(-50%, -50%);
  background-color: rgba(20, 5, 5, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-animation {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.card-animation .card-body {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.star-effect {
  height: 100%;
  width: 100%;
  border-radius: 50%;

  animation: glow 2s ease-in-out infinite;
}

.gachButton:disabled{
  background: gray;
  color: white;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px #ff0000, 0 0 10px #ff0000, 0 0 15px #ff0000,
      0 0 20px #ff0000;
  }
  50% {
    box-shadow: 0 0 10px #ff0000, 0 0 20px #ff0000, 0 0 30px #ff0000,
      0 0 40px #ff0000;
  }
  100% {
    box-shadow: 0 0 5px #ff0000, 0 0 10px #ff0000, 0 0 15px #ff0000,
      0 0 20px #ff0000;
  }
}
