.navHeader {
  position: sticky;
  top: 0;
  background: linear-gradient(to right, #ff7f50, goldenrod, red);
  border: solid black;
  height: 100px;
  box-sizing: border-box;
  z-index: 1;
  display: grid;
  grid-template-columns: 18% 80%;
  column-gap: 2%;
}

.logo {
  width: 100px;
  height: 50px;
  margin-top: 10px;
  margin-left: 20px;
  background: transparent;
}

.logo:hover {
  transform: scale(1.3);
  transition: transform 0.4s;
}

.nav-right {
  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 10%;
}

.links-nav,
.links-auth, .info-modals {
  display: flex;
  align-items: flex-end;
  justify-content: left;
}

.info-modals, .links-auth {
margin-right: 20px;
justify-content: right;
}

.logout-button {
  border: none;
  font-family: Futura;
}

.log-btn {
  color: white;
  background-color:rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-size: 23px;
  border-radius: 5px;
  padding: 5px;
  font-style: italic;
  margin-left: 20px;
  box-sizing: border-box;
  height: 40px;
  padding: 2% 5%;
  border: 2px goldenrod solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.log-btn span {
  line-height: 100%;
}

.log-btn:hover {
  color: red;
  background-color: black;
  border: solid goldenrod 2px;
  border-radius: 8px;
  font-weight: bold;
  z-index: 1;
}

.profile-link,
.game-link,
.displayInstructions,
.displayTeamName {
  text-decoration: none;
  font-size: 25px;
  padding: 0 5%;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: Futura;
  border: outset rgb(115, 115, 207);
  color: slateblue;
  background-image: linear-gradient(
    to bottom,
    rgb(143, 194, 239),
    rgb(124, 226, 226),
    purple
  );
  box-shadow: 0 0 20px rgb(143, 194, 239);
  border-top-left-radius: 25px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 25px;
  position: relative;
  text-shadow: 0 0 20px rgba(137, 43, 226, 0.76);
  align-items: center;
  text-align: center;
}

.profile-link::before,
.game-link::before,
.displayInstructions:before,
.displayTeamName:before {
  box-sizing: border-box;
  content: "";
  display: flex;
  width: 90%;
  height: 96%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 2% 5%;
  border: outset aquamarine;
  border-top-left-radius: 20px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 20px;
}

.profile-link:hover,
.game-link:hover {
  transform: scale(1.1);
  transition: transform 0.2s;
  text-shadow: 0 0 10px rgba(137, 43, 226, 0.234);
  box-shadow: 0 0 50px rgb(143, 194, 239);
  border-bottom-right-radius: 25px;
  z-index: 2;
}

.active-link {
  z-index: 1;
  border-bottom-right-radius: 25px;
  transform: scale(1.1);
  transition: transform 0.2s;
  animation: profileAnimation 2s linear infinite;
  background-size: 100% 200%;
  color: aliceblue;
  /* font-size: 30px; */
  background-image: linear-gradient(
    to bottom,
    rgb(143, 194, 239) 0%,
    rgb(124, 134, 226) 15%,
    rgba(110, 33, 173, 0.521) 30%,
    purple 45%,
    rgb(56, 15, 206) 60%,
    rgb(124, 129, 226) 75%,
    rgb(143, 194, 239) 90%
  );
}

@keyframes profileAnimation {
  0% {
    background-position: 0 100%;
  }
  100% {
    background-position: 0 -100%;
  }
}
