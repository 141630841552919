.player-stat-badge {
    height: 100%;
    border-radius: 5px;
    border: 2px solid yellow;
    padding: 3px 2px;
    padding: 2%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 30% 69%;
    column-gap: 1%;
  }
  
  .player-stat-content {
    box-sizing: border-box;
    position: relative;
  }

  .avatar-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
  }

  .player-attack {
    font-size: 20px;
  }

  .player-info{
    font-size: 20px;
    /* margin-bottom: 5px; */
  }

  .player-stat-content h2 {
    width: 100%;
    font-weight: bold;
    font-size: 40px;
    /* margin-bottom: 10px; */
  }
  
  .player-stat-avatar {
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: rgba(206, 49, 49, 0.401);
  }

  .health-bar-container{
    display: flex;
    flex-direction: column;
    column-gap: 1%;
    font-size: 20px;
  }

  .health-bar-wrapper{
    height: 30px;
    box-sizing: border-box;
  }

  
  .health-bar.outter,
  .health-bar.inner {
    content: "";
    border-radius: 10px;
    line-height: 4px;
    box-sizing: border-box;
  }
  
  .health-bar.outter {
    position: relative;
    height: 70%;
    box-shadow: 0 0 5px rgb(138, 93, 9);
    background-color: rgba(204, 204, 204, 0.5);
    overflow: hidden;
  }

  .health-bar.inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    background-image: linear-gradient(to right, #8aff8a 0%, #59ff59 10%, #00ff00 40%, #00ff00 60%,#59ff59 90%, #8aff8a 100%);
    /* background-image: linear-gradient(to right, #ff6e6e 0%, #ff4444 10%, #ff0000 40%, #ff0000 60%, #ff4444 90%, #ff6e6e 100%); */
    box-shadow: 0 0 2px limegreen;
    /* z-index: 1; */
    animation: shift 2s linear infinite;
    background-size: 200% 100%;
  }
  
  @keyframes shift {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }

  .flash-red {
    animation: flash 0.3s infinite;
  }
  
  @keyframes flash {
    0% {
      color: inherit;
      text-shadow: 0 0 4px rgba(255, 0, 0, 0.6);
    }
    50% {
      color: red;
      text-shadow: 0 0 4px orange
    }
    100% {
      color: inherit;
      text-shadow: 0 0 4px rgba(255, 0, 0, 0.6);
    }
  }
  
  .flash-red {
    animation: flash 1s linear;
  }
  