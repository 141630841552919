.game-page-container {
  width: 100%;
  display: flex;
  column-gap: 1%;
  height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
  background-image: linear-gradient(to bottom, lightblue, aqua, white, aqua, lightblue);

  box-sizing: border-box;
  min-width: 950px;
}
.game-page-content {
  box-sizing: border-box;
  height: 100%;
}

.game-page-content.left {
  width: 40%;
  display: grid;
  grid-template-rows: 30% 10% 56%;
  row-gap: 2%;
  box-sizing: border-box;
  background-image: url(https://img.freepik.com/free-vector/modern-technological-elements-with-transparent-background_1035-7108.jpg?w=2000);
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 455px;
  height: 100%;
  background-color: rgba(0, 255, 247, 0.064);
  text-shadow: 0 0 20px teal;
}

.game-page-content.right {
  width: 59%;
  display: grid;
  grid-template-rows: 54% 44%;
  row-gap: 2%;
  position: relative;
  box-sizing: border-box;
  min-width: 500px;
  background-image: url(../../assets/images/background4.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: gold;
  animation: switchbg 15s ease infinite;
}

@keyframes switchbg {
  0% {
    background-image: url(../../assets/images/background1.png);
  }
  25% {
    background-image: url(../../assets/images/background2.png);
  }
  50% {
    background-image: url(../../assets/images/background3.png);
  }
  75% {
    background-image: url(../../assets/images/background4.png);
  }
  100% {
    background-image: url(../../assets/images/background1.png);
  }
}

.game-content.player-board {
  display: grid;
  grid-template-columns: 49% 50%;
  column-gap: 1%;
}

.game-content {
  box-sizing: border-box;
}

.game-questions {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
}

.game-content.enemy-board {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}
.answer-result {
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  border-radius: 5px;
  color: white;
  padding: 3px;
  box-sizing: border-box;
  text-align: center;
}

.answered-stats {
  display: flex;
  font-size: 20px;
  align-items: center;
  padding: 5px 10px;
}

.answered-stats > div:not(:last-child) {
  margin-right: 10%; /* Adjust the margin value as needed */
}

.answer-result.correct {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 128, 0, 0.7);
}

.answer-result.incorrect {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 0, 0, 0.7);
}

.result {
  color: black;
  text-shadow: 0 0 5px rgba(255, 255, 255, 1);
}

.selected-answer {
  color: white;
  font-size: 18px;
  text-shadow: none;
}

.Card-Choice-Container {
display: flex;
padding: 5%;
box-sizing: border-box;
}
