.team-modal-container {
  border-radius: 10px;
  background: linear-gradient(to right, goldenrod, rgb(185, 146, 146), goldenrod);
  width: 100%;
  height: 100%;
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
  font-size: 20px;
  overflow: hidden;
}

.team-tabs-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 23% 23% 23% 23%;
  justify-content: space-between;
  border-radius: 15px;
  box-sizing: border-box;
  background: radial-gradient(rgba(0, 128, 0, 0.609), rgba(128, 0, 128, 0.274), rgba(255, 166, 0, 0.494));
}

.team-tabs{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.team-tabs:hover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.7);
  border-radius: 10px;
  transform: scale(1.2);
  transition: transform 0.5s;
  background-color: rgba(120, 120, 120, 0.8);
}

.pos-info {
  font-size: 25px;
  display: flex;
  flex-direction: column;
  height: 15%;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.team-pos {
  text-decoration: underline;
  font-weight: bold;
  font-size: 30px;
}

.team-tabs:hover .pos-info {
  
}

.team-tabs img {
  width: 90%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 50%;
}

.link-section {
  display: grid;
  grid-template-columns: 25% 25% 25%;
  justify-content: space-evenly;

}
.link-section a {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.link-section svg {
  width: 100%;
  height: 100%
}


.team-social-icon {
  width: 20%;
  height: 20%;
}

.team-social-icon:hover {
  transform: scale(1.2);
  transition: transform 0.5s;
  fill: rgba(255, 255, 255, 0.8)
}