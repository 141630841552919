.player-character {
  display: grid;
  grid-template-columns: 50% 50%;
}

.player-image {
  display: flex;
  width: 100%;
  /* background: transparent; */
  overflow: hidden;
  align-items: center;
  margin: 5%;
  padding: 2%;
  border-radius: 3px;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}

.player-image::before {
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  background-size: 100% 100%;
  /* z-index: -1; */

  background: linear-gradient(to right, rgb(104, 145, 123), brown, rgba(54, 15, 181, 0.878));
  animation: borderAnimation 2s linear infinite;
}

@keyframes borderAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.player-picture {
  border-radius: 3px;
  z-index: 1;
  width: 100%;
  padding: 1%;
  box-sizing: border-box;
  background: transparent;
  height: 194px;
  object-fit: cover;
  /* margin-left: 20px; */
  display: flex;
  /* border: 5px ridge; */
  background-color: rgba(219, 219, 219, 0.7);
}

.player-sprite {
  height: 150px;
  display: flex;
  /* position: absolute; */
  left: 25%;
  bottom: 5%;
  animation: playerHover 1.5s infinite alternate;
}
.p-sprite {
  transform: scaleX(-1) rotate(20deg);
}

.playerExplosion {
  position: absolute;
  height: 100%;
  width: 100%;
  top: -10px;
  left: -10px;
}

@keyframes playerHover {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
