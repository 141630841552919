.webcam-capture {
  width: 320px;
  /* height: 280px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-right: 40%; */
}

.capture-button {
  width: 100px; /* Adjust the width as per your preference */
  margin-top: 10px; /* Add spacing between the webcam and the button */
  
}
