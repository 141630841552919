#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* width: 100%;
  height: 100%; */
  background-color: rgba(0, 0, 0, 0.7);
  /* z-index: 4; */
}

#modal-content {
  position: absolute;
  width: 60%;
  height: 80%;
  border-radius: 10px;
}