footer {
  color: red;
  /* background-color: goldenrod; */
  background: linear-gradient(to right, #ff7f50, goldenrod, red);
  border: solid black;
  border-radius: 3px;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 30px;
}

.MainSplashDiv {
  height: calc(100vh - 100px - 30px);
  width: 100%;
  background-color: black;
  background: linear-gradient(to right, red, #ff4f00, goldenrod, black);
}

.aaCardImg {
  width: 10%;
  height: 10%;
}

.gameIntro {
  background-color: rgba(226, 187, 98, 0.477);
  width: 75%;
  height: 50%;
  border-radius: 18px;
  position: relative;
  padding: 5%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.introText {
  color: wheat;
  font-size: 50px;
  text-align: center;
}

.logoHolder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardLogo1,
.cardLogo2 {
  position: absolute;
  width: 100px;
  height: 100px;
}

.cardLogo1,
.cardLogo2 {
  top: calc(100% - 50px);
}

.cardLogo1 {
  left: -50px;
}

.cardLogo2{
  right: -50px;
}

.cardLogo1:hover,
.cardLogo2:hover {
  animation: rotate 1.2s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
